export const themeDetails = {
  company: 'TLS Verkko Oy',
  brandName: 'TLS Verkko',
  theme: 'tls',
  services: ['electricity', 'contractInfo'],
  incomingServices: [],
  customerServiceEmail: 'asiakaspalvelu@tls.fi',
  feedbackEmail: 'asiakaspalvelu@tls.fi',
}

/* added property to control drillable value -ADP */
export const Options = {
  quarterlyDataAllowed: true, //enabled for testing 
}

export const styledComponentsThemes = {
  default: {
    'nav-color': '#005685',
    'inline-nav-color': '#005685',
    'nav-color:hover': '#009fe3',
  },
  inverted: {
    'nav-color': '#fff',
    'inline-nav-color': '#fff',
    'nav-color:hover': '#fff',
  },
}

export const textStrings = {
  home: {
    introduction: 'Tervetuloa TLS Verkko Oy:n Online -palveluun',
    subHeader:
      'Täältä löydät ajankohtaiset tiedot liittyen energiankulutukseesi.',
  },
}

export const footerContent = {
  contactInfo: [
    {
      icon: 'fa fa-phone',
      type: 'phone',
      href: 'tel:016532030',
      title: 'Vikailmoitukset',
      content: 'puh. 016-532 030',
    },
    {
      icon: 'fa fa-envelope',
      type: 'mixed',
      href: 'tel:016532050',
      href2: 'mailto:asiakaspalvelu@tls.fi',
      title: 'Asiakaspalvelu',
      content: ['puh. 016-532 050', 'asiakaspalvelu@tls.fi'],
    },
  ],
  website: 'https://www.tornionlaaksonsahko.fi/',
}
